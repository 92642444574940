import { Mail, Phone } from "lucide-react";
import { useEffect, useState } from "react";
import { Link as LinkS } from "react-scroll";
import useMediaQuery from "../../hooks/useMediaQuery";
import Logo from "../logo";

const navbarLinks: {
  id: number;
  title: string;
  href: string;
  type?: "link";
  offset: number;
}[] = [
  {
    id: 0,
    title: "Domov",
    href: "home",
    offset: -100,
  },
  {
    id: 1,
    title: "Storitve",
    href: "our-services",
    offset: -100,
  },
  {
    id: 2,
    title: "Reference",
    href: "references",
    offset: -100,
  },
  {
    id: 3,
    title: "O nas",
    href: "about-company",
    offset: -100,
  },
  {
    id: 4,
    title: "Kontakt",
    href: "contact",
    offset: -100,
  },
];

const Header = () => {
  const { isMobile } = useMediaQuery(992);
  const [isOpen, setIsOpen] = useState(false);
  const [transformHeader, setTransformHeader] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const changeNav = () => {
    if (window.scrollY >= 70) {
      setTransformHeader(true);
    } else {
      setTransformHeader(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
    return () => {
      window.removeEventListener("scroll", changeNav);
    };
  }, []);

  return (
    <header
      className={
        transformHeader ? "header container active" : "header container"
      }
    >
      <Logo />
      {!isMobile && (
        <div className="header-upper">
          <div className="header-upper-left"></div>
          <div className="header-upper-right">
            <div className="header-contact-block">
              <Phone size={20} />{" "}
              <a href="tel:+38631333900" title="tel:+38631333900">
                +386 31 333 900
              </a>
            </div>
            <div className="header-contact-block">
              <Mail size={20} />{" "}
              <a
                href="mailto:bojan.verc@mgv.si"
                title="mailto:bojan.verc@mgv.si"
              >
                bojan.verc@mgv.si
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="header__wrap">
        {isMobile ? (
          <>
            <div
              className={
                isOpen ? "navbar-toggle-btn active" : "navbar-toggle-btn"
              }
              onClick={() => setIsOpen(!isOpen)}
            >
              <span></span>
              <span></span>
            </div>
            <div className="header-mobile-menu-responsive">
              <div className="header-mobile-menu-responsive__inner">
                <nav className="nav">
                  <ul>
                    {navbarLinks.map((link) => (
                      <li
                        key={link.id}
                        className={link.id === 4 ? "contact-link" : ""}
                      >
                        <LinkS
                          to={link.href}
                          smooth={true}
                          spy={true}
                          offset={link.offset}
                          duration={1000}
                          href={link.href}
                          onClick={() => toggleMenu()}
                        >
                          {link.title}
                        </LinkS>
                      </li>
                    ))}
                  </ul>
                </nav>
                <div className="contact-wrapper">
                  <div className="header-contact-block">
                    <Phone size={20} />{" "}
                    <a href="tel:+38631333900" title="tel:+38631333900">
                      +386 31 333 900
                    </a>
                  </div>
                  <div className="header-contact-block">
                    <Mail size={20} />{" "}
                    <a
                      href="mailto:bojan.verc@mgv.si"
                      title="mailto:bojan.verc@mgv.si"
                    >
                      bojan.verc@mgv.si
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <nav className="nav">
            <ul>
              {navbarLinks.map((link) => (
                <li
                  key={link.id}
                  className={link.id === 4 ? "contact-link" : ""}
                >
                  <LinkS
                    to={link.href}
                    smooth={true}
                    spy={true}
                    offset={link.offset}
                    duration={1000}
                  >
                    {link.title}
                  </LinkS>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
