import { useState } from 'react'
import Modal from '../../modal'

interface IProps {
  sub: {
    id: number
    title: string
    icon: string
    description: string
  }
}

const ServiceBlock = ({ sub }: IProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <button className="tab" key={sub.id}>
        <img
          className={sub.id === 3 || sub.id === 4 ? 'opacity-7' : ''}
          src={sub.icon}
          alt={sub.title}
          title={sub.title}
          width={100}
          height={80}
        />
        <h3>{sub.title}</h3>
      </button>
      <Modal open={isOpen} closeModal={() => setIsOpen(false)} sub={sub} />
    </>
  )
}

export default ServiceBlock
