import { routes } from '../../constants/routesConstants'
import { Link } from 'react-router-dom'

const Logo = () => {
  return (
    <div className="logo">
      <Link to={routes.HOME}>
        <img
          src="/images/logo.png"
          alt="MGV"
          title="MGV"
          width={135}
          height={63}
        />
      </Link>
    </div>
  )
}

export default Logo
