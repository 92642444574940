import Hero from "../components/hero";
import ServicesTabs from "../components/services";
import References from "../components/references";
import AboutCompany from "../components/about-company";

const Home = () => {
  return (
    <main className="page-container">
      <Hero />
      <ServicesTabs />
      <References />
      <AboutCompany />
    </main>
  );
};

export default Home;
