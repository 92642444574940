import { memo } from 'react'

import Logo from '../logo'
import LogoBlocktech from '../logo-blocktech'

const Footer = () => {
  return (
    <div className="pl-2 pr-2">
      <div id="contact" className="img-wrapper mt-8 container"></div>
      <footer className="container mt-2">
        <div className="p-2">
          <div className="footer-top">
            <div className="footer-logo">
              <Logo />
              <p className="mt-2 mb-2 font-14">
                <i>je partnersko podjetje</i>
              </p>
              <LogoBlocktech />
            </div>
            <ul className="footer-contact">
              <li className="mb-1 fw-bold font-14">MGV, d.o.o., Ljubljana</li>
              <li className="mb-1 fw-bold font-14">
                Cesta ljubljanske brigade 23a
              </li>
              <li className="mb-1 fw-bold font-14">1000 Ljubljana</li>
              <li className="mb-1">
                <span className="fw-bold font-14">tel.:</span>{' '}
                <a
                  className="font-14"
                  href="tel:+38615108426"
                  title="tel:+38615108426">
                  +386 1 510 84 26
                </a>
              </li>
              <li className="mb-1">
                <span className="fw-bold font-14">GSM:</span>{' '}
                <a
                  className="font-14"
                  href="tel:+38631333900"
                  title="tel:+38631333900">
                  +386 31 333 900
                </a>
              </li>
              <li>
                <span className="fw-bold font-14">e-pošta:</span>{' '}
                <a
                  className="font-14"
                  href="mailto:bojan.verc@mgv.si"
                  title="mailto:bojan.verc@mgv.si">
                  bojan.verc@mgv.si
                </a>
              </li>
            </ul>
            <div className="footer-map">
              <iframe
              title='Google maps'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2767.224435657628!2d14.475631175876396!3d46.0865035917036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477acd3020acb0df%3A0x4a6257400c4bb02e!2sCesta%20Ljubljanske%20brigade%2023a%2C%201000%20Ljubljana!5e0!3m2!1ssl!2ssi!4v1693432223960!5m2!1ssl!2ssi"
                width="600"
                height="450"
                style={{ border: 0 }}
                loading="lazy"></iframe>
            </div>
          </div>
          <div className="footer-bottom">
            <p className="font-12 fw-medium">© 2023</p>
            <p className="font-12 fw-medium">
              MGV d.o.o., Vse pravice pridržane.
            </p>
            <p className="font-12 fw-medium">
              Izvajalec:{' '}
              <a
                href="mailto:nejcrogelsek0@gmail.com"
                title="mailto:nejcrogelsek0@gmail.com">
                Nejc Rogelšek s.p.
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default memo(Footer)
