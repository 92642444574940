import { Link } from "react-router-dom";
import { routes } from "../../constants/routesConstants";

const LogoBlocktech = () => {
  return (
    <div className="logo">
      <Link to={routes.HOME}>
        <img
          src="/images/logo_color_no_background.png"
          alt="BLOCKTECH"
          title="BLOCKTECH. BE SMART. DO IT RIGHT."
          width={206}
          height={59}
        />
      </Link>
    </div>
  );
};

export default LogoBlocktech;
