import WhyUs from '../why-us'
import ServiceBlock from './service-block'

const ServicesTabs = () => {
  const sub_sections = [
    {
      id: 1,
      title: 'Projektiranje NN in SN elektro energetskih sistemov',
      icon: '/images/service2.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
    {
      id: 2,
      title: 'Projektiranje infrastrukture e-mobilnosti',
      icon: '/images/service1.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
    {
      id: 3,
      title:
        'Projektiranje naprednih sistemov EMS, kompenzacijskih naprav in BSS',
      icon: '/images/service3.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
    {
      id: 4,
      title: 'Projektiranje sistemov proizvodnje električne energije',
      icon: '/images/service4.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
    {
      id: 5,
      title:
        'Strokovna in zanesljiva izvedba elektro energetske infrastrukture, sistemov in postrojenj',
      icon: '/images/service5.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
    {
      id: 6,
      title:
        'Strokovni pregled in nadzor izvedenih projektov v elektro energetiki, svetovanje in inženiring',
      icon: '/images/service6.png',
      description:
        'Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat asperiores delectus aperiam laborum earum voluptatum, dolorum culpa eius aliquam alias ratione? Nihil eveniet non, quasi illo reiciendis repudiandae iure harum.',
    },
  ]
  return (
    <>
      <section id="our-services" className="our-services mt-8">
        <div className="container">
          <div className="our-services-wrapper">
            <h2 className="page-title">Naše storitve</h2>
            <p className="mb-1">
              Naše bogate izkušnje pri načrtovanju in izvedbi projektov s
              področja energetike povezujemo z najvišjimi strokovnimi standardi
              in natančno ter premišljeno obravnavo tehnično - tehnoloških
              izzivov svojih naročnikov.
            </p>
          </div>
          <div className="tabs">
            {sub_sections.map((sub) => (
              <ServiceBlock key={sub.id} sub={sub} />
            ))}
          </div>
          <WhyUs />
        </div>
      </section>
    </>
  )
}

export default ServicesTabs
