import Footer from "./components/footer";
import Header from "./components/header";
import Routes from "./routes/Routes";

function App() {
  return (
    <>
      <Header />
      <Routes />
      <Footer />
    </>
  );
}

export default App;
