import { Autoplay, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const Hero = () => {
  const heroItems = [
    {
      id: '1',
      src: '/images/hero1.jpg',
      title:
        'Vodilni strokovni partner v reševanju in izvedbi kompleksnejših elektroenergetskih sistemov',
      width: 'hero1',
    },
    {
      id: '2',
      src: '/images/hero2.jpg',
      title:
        'Osredotočeni na trajnost ne strmimo k kvantiteti projektov, pač pa zagotavljamo najvišjo kvaliteto storitev',
      width: 'hero2',
      backgroundPosition: 'top',
    },
    {
      id: '3',
      src: '/images/hero3.jpg',
      title:
        'Rešujemo izzive vse od polnilne infrastrukture, proizvodnih sistemov električne energije ter baterijskih hranilnikov',
      width: 'hero3',
      backgroundPositionX: '12%',
    },
  ]
  return (
    <section id="home" className="hero">
      <div className="container">
        <Swiper
          className="hero-swiper"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            enabled: true,
            clickable: true,
          }}
          loop={true}
          slidesPerView={1}
          modules={[Autoplay, Pagination]}>
          {heroItems.map((h) => (
            <SwiperSlide key={h.id}>
              <div>
                {/* <Image src={h.src} alt={h.title} title={h.title} fill /> */}
                <div
                  className="hero-image"
                  style={{
                    backgroundImage: `url(${h.src})`,
                    backgroundPositionX: h.backgroundPositionX,
                    backgroundPosition: h.backgroundPosition,
                  }}></div>
                <div className="content">
                  <h1 className={h.width}>{h.title}</h1>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}

export default Hero
