import { Check } from 'lucide-react'

const WhyUs = () => {
  return (
    <div className="why-us mt-8">
      <div>
        <h3>
          Uspešna podjetja s področja elektroinštalacijske dejavnosti in
          industrije že vrsto let zaupajo podjetju MGV, ker:
        </h3>
        <ul>
          <li>
            <Check />
            <span>zagotavljamo odlično celovito rešitev ob primerni ceni,</span>
          </li>
          <li>
            <Check />
            <span>
              so naše rešitve narejene po meri, jih projektiramo in proizvedemo
              v lastni domeni,
            </span>
          </li>
          <li>
            <Check />
            <span>je naša velika prednost hitrost posredovanja,</span>
          </li>
          <li>
            <Check />
            <span>
              izvajamo celovite rešitve za pridobitev nove moči električne
              energije,
            </span>
          </li>
          <li>
            <Check />
            <span>
              nudimo obsežne storitve vse od svetovanja, projektiranja,
              proizvodnje in do montaže, izvedene rešitve lahko tudi vzdržujemo
            </span>
          </li>
          <li>
            <Check />
            <span>
              pripravimo tehnične rešitve od začetka do konca, kot na primer
              izgradnjo transformatorske postaje od osnutka do končne izvedbe.
            </span>
          </li>
        </ul>
      </div>
      <div>
        <div className="about-image-wrapper">
          <img
            id="about-image"
            src="/images/about-company1.jpg"
            sizes="370x209"
            title="Bojan Verč, direktor in ustanovitelj podjetja MGV"
            alt="Bojan Verč, direktor in ustanovitelj podjetja MGV"
          />
          <i>Bojan Verč, direktor in ustanovitelj podjetja MGV</i>
        </div>
      </div>
    </div>
  )
}

export default WhyUs
