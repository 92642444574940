const AboutCompany = () => {
  return (
    <section id="about-company" className="about-company mt-8">
      <div className="container">
        <div className="about-company-wrapper">
          <h1 className="page-title">O podjetju</h1>
          <p className="strong-text">
            MGV je podjetje s tradicijo in zavidljivimi referencami iz področja
            kompleksne elektro energetike.
            <br />
            Našim poslovnim strankam in kupcem nudimo le najboljše.Poskrbimo, da
            so storitve opravljene
            <br />
            na najvišjem možnem nivoju in ob tem nudimo vso strokovno podporo.
            <br />
            Zato sodelujemo le z najbolj zanesljivimi partnerji.
          </p>
          <p className="mt-7 mb-7 spacing-text">
            Podjetje je bilo ustanovljeno leta 1989 pod imenom MEGAVAR in se je
            prvotno ukvarjalo z dejavnostjo kompenzacije jalove energije nizke
            in srednje napetosti. Širitev dejavnosti na proizvodnjo in montažo
            elektro energetskih naprav ter napeljav je v letu 1996 botrovala
            tudi spremembi imena podjetja iz MEGAVAR v MGV. Od leta 1997 naprej
            že izvajamo ukrepe povečanja energetske učinkovitosti v podjetjih.
            Na področje zelene energije in izvajanje trajnostnih energetskih
            sistemov je podjetje vstopilo leta 2010. Danes so najkomplejsnejši
            sistemi trajnostne transformacije v elektro energetiki naša
            realnost.
          </p>
          <p className="strong-text text-center">
            <strong>
              Rešujemo izzive vse od polnilne infrastrukture, proizvodnih
              sistemov električne energije in baterijskih hranilnikov.
            </strong>
          </p>
        </div>
      </div>
    </section>
  )
}

export default AboutCompany
