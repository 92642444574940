import { useEffect, useRef, useState } from 'react'

const useMediaQuery = (breakpoint: number) => {
  const [isMobile, toggle] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const checkWidth = useRef(() => {})
  checkWidth.current = () => {
    if (window.innerWidth < breakpoint) {
      toggle(true)
    } else {
      toggle(false)
    }
  }

  useEffect(() => {
    checkWidth.current()
    window.addEventListener('resize', checkWidth.current)
    return () => {
      window.removeEventListener('resize', checkWidth.current)
    }
  }, [])

  return { isMobile }
}

export default useMediaQuery
