

import lgThumbnail from 'lightgallery/plugins/thumbnail'
import LightGallery from 'lightgallery/react'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

const ReferencesSwiper = () => {
  const references = [
    {
      id: '1',
      title: 'Qlandia Kamnik, Slovenija',
      description: ['Moč generatorja: 450 kW'],
      images: ['/images/referenca1.png'],
    },
    {
      id: '2',
      title: 'Qlandia Novo mesto, Slovenija',
      description: ['Moč generatorja: 970 kW'],
      images: ['/images/referenca2_1.png', '/images/referenca2_2.png'],
    },
    {
      id: '3',
      title: 'Liv Postojna, Slovenija',
      description: ['Moč generatorja: 450 kW'],
      images: ['/images/referenca3.png'],
    },
    {
      id: '4',
      title: 'LIKO Borovnica, Slovenija',
      description: ['Moč generatorja: 1440 kW'],
      images: ['/images/referenca4_1.png', '/images/referenca4_2.png'],
    },
    {
      id: '5',
      title: 'Logatec, Slovenija',
      description: ['Moč generatorja: 486 kW'],
      images: ['/images/referenca5.png'],
    },
    {
      id: '6',
      title: 'BTC Logistični center, Slovenija',
      description: ['Moč generatorja: 900 kW'],
      images: ['/images/referenca6.png'],
    },
    {
      id: '7',
      title: 'Podgorje, Slovenija',
      description: ['Moč generatorja: 820 kW'],
      images: ['/images/referenca7.png'],
    },
    {
      id: '8',
      title: 'Interevropa Celje, Slovenija ',
      description: ['Moč generatorja: 1800 kW'],
      images: ['/images/referenca8.jpg'],
    },
    {
      id: '9',
      title: 'Merkur Kranj, Slovenija',
      description: ['Moč generatorja: 408 kW'],
      images: ['/images/referenca9.jpg'],
    },
    {
      id: '10',
      title: 'Summit Motors, Ljubljana, Slovenija',
      description: [
        'Moč generatorja: 124 kW',
        'Moč baterijskega hranilnika: 125 kW / 218 kWh',
      ],
      images: ['/images/referenca10.jpg'],
    },
    {
      id: '11',
      title: 'Marušič, Opatje selo, Slovenija',
      description: ['Moč generatorja: 812 kW / 1048 kW'],
      images: ['/images/referenca11_1.jpg', '/images/referenca11_2.jpg'],
    },
  ]

  const onInit = () => {
    console.log('lightGallery has been initialized')
  }
  return (
    <Swiper
      className="references-swiper"
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        enabled: true,
        clickable: true,
      }}
      loop={true}
      spaceBetween={0}
      slidesPerView={1}
      breakpoints={{
        768: {
          slidesPerView: 2,
          slidesPerGroup: 2,
        },
        991: {
          slidesPerView: 3,
          slidesPerGroup: 3,
        },
        1200: {
          slidesPerView: 4,
          slidesPerGroup: 4,
        },
      }}
      modules={[Autoplay, Pagination, Navigation]}>
      {references.map((reference) => (
        <SwiperSlide key={reference.id}>
          <div>
            <h3>{reference.title}</h3>
            {reference.description.map((desc) => (
              <p key={desc}>{desc}</p>
            ))}
            <LightGallery
              onInit={onInit}
              speed={300}
              showCloseIcon={true}
              closable={true}
              plugins={[lgThumbnail]}
              thumbnail={true}>
              {reference.images.map((img) => (
                <a key={img} href={img} title={reference.title}>
                  <img
                    src={img}
                    alt={reference.title}
                    title={reference.title}
                  />
                </a>
              ))}
            </LightGallery>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}

export default ReferencesSwiper
