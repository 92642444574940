import ReferencesSwiper from '../references-swiper'

const References = () => {
  return (
    <section id="references" className="references mt-8">
      <div className="container">
        <div className="references-wrapper">
          <h2 className="page-title">Reference</h2>
          <p>
            V izgradnji novih ali rekonstrukciji obstoječih energetskih sistemov
            ter objektov upoštevamo najvišje standarde in okoljevarstvene
            zahteve, sodelovanjemo z uveljavljeni podjetji. Projektom
            zagotavljamo minimalne stroške vzdrževanja, zanesljivost delovanja
            in optimalen energetski izkoristek.
          </p>
        </div>
        <ReferencesSwiper />
      </div>
    </section>
  )
}

export default References
