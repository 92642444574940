import { FC } from "react";
import {
  RouteProps,
  Routes as Switch,
  Route,
  Navigate,
} from "react-router-dom";

import Home from "../pages/Home";
import { routes } from "../constants/routesConstants";

export enum RouteType {
  PUBLIC,
}
interface AppRoute extends Omit<RouteProps, "type"> {
  type?: RouteType;
}
export const AppRoutes: AppRoute[] = [
  // Public Routes
  {
    type: RouteType.PUBLIC,
    path: "/",
    children: <Home />,
  },
];

const Routes: FC = () => {
  return (
    <Switch>
      {AppRoutes.map((r) => (
        <Route key={`${r.path}`} path={`/${r.path}`} element={r.children} />
      ))}
      <Route path="*" element={<Navigate to={routes.HOME} />} />
    </Switch>
  );
};

export default Routes;
