import { X as CloseIcon } from 'lucide-react'
import ReactModal from 'react-modal'

interface IProps {
  open: boolean
  closeModal: () => void
  sub: {
    id: number
    title: string
    icon: string
    description: string
  }
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: 'min(95%, 500px)',
    borderRadius: '4px',
  },
  overlay: {
    zIndex: '50',
    background: 'rgba(0,0,0,0.4)',
  },
}

const Modal = ({ open, closeModal, sub }: IProps) => {
  return (
    <ReactModal isOpen={open} onRequestClose={closeModal} style={customStyles}>
      <div className="modal-header">
        <CloseIcon onClick={closeModal} />
      </div>
      <h4>{sub.title}</h4>
      <p>{sub.description}</p>
    </ReactModal>
  )
}

export default Modal
